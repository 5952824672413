import React from "react"
import { graphql } from 'gatsby'
import Image from '../components/image-provider'

import Layout from "../components/layout"
import SEO from "../components/seo"

import ReactSVG from '../graphics/react.svg'
import GatsbySVG from '../graphics/gatsby.svg'
import NextSVG from '../graphics/next.svg'
import PythonSVG from '../graphics/python.svg'
import GraphQLSVG from '../graphics/graphql.svg'
import NodeSVG from '../graphics/node.svg'
import Html5SVG from '../graphics/html5.svg'
import Css3SVG from '../graphics/css3.svg'
import ShopifySVG from '../graphics/shopify.svg'
import AmplifySVG from '../graphics/amplify.svg'
import AwsSVG from '../graphics/aws.svg'
import GoogleCloudSVG from '../graphics/googlecloud.svg'
import PhotoshopSVG from '../graphics/photoshop.svg'
import IllustratorSVG from '../graphics/illustrator.svg'
import XdSVG from '../graphics/xd.svg'
import FigmaSVG from '../graphics/figma.svg'

import IPSSVG from '../graphics/indianperformancesolutions.svg'
import Route41SVG from '../graphics/route41roadhouse.svg'
import RFJSVG from '../graphics/relayforjustice.svg'


const AboutPage = ({ data, location }) => {

    const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize

    const TechSection = ({ icon, text, alt }) => (
        <div className="w-1/2 mt-6 laptop:w-1/8">
            <img className="w-1/2 mx-auto" src={icon} alt={alt} />
            <h2 className='text-center text-sm mt-4 font-semibold'>{text}</h2>
        </div>
    )

    return(
        <Layout>
            <SEO
                title="About Us"
                description="We are web developers who are passionate about cutting edge software, the latest programming languages, and web design. We produce highly performant applications with lasting integrity."
                image={socialCardImage}
                pathname={location.pathname}
            />
            <Image
                fileName="rarechicagomobileaboutusbanner.jpg"
                className="laptop:hidden"
                alt="Neon colored photo of old cassettes, a commodore personal computer, a first-generation Gameboy, and various other electronics. Text `about rare` overlayed on photo."
            />
            <Image
                fileName="rarechicagodesktopaboutusbanner.jpg"
                className="hidden laptop:block"
                alt="Neon colored photo of old cassettes, a commodore personal computer, a first-generation Gameboy, and various other electronics. Text `about rare` overlayed on photo."
                style={{ maxHeight: '426px' }}
            />

            <div className="max-viewport mx-auto">

                <h2 className="text-base text-center mt-12">Join us at the forefront of innovation</h2>

                <div className="mt-12 mx-auto" style={{ maxWidth: '960px' }}>
                    <p className="px-20">We are creators who are passionate about developing the highest quality software. We build user experiences that provide functionality, personality, and lasting impressions.</p>
                    <p className="px-20 mt-8">You have a unique vision for your business. We're here to help you share it. The internet is a large room, so speak louder for the people in back.</p>
                </div>
                <div className="w-full px-4 mt-12 mx-auto" style={{ maxWidth: '960px' }}>
                    <hr className="w-full h-2px" />
                </div>

                <div className="w-full flex flex-wrap mt-12 pb-8 laptop:pb-20 laptop:mt-6">

                    <div className="w-full text-center">
                        <h2 className="mt-4 mb-8 opacity-70">Our Technologies</h2>
                    </div>

                    <TechSection
                        icon={ReactSVG}
                        alt="React logo"
                        text="React"
                    />
                    <TechSection
                        icon={GatsbySVG}
                        alt="Gatsby logo"
                        text="Gatsby"
                    />
                    <TechSection
                        icon={NextSVG}
                        alt="Next.js logo"
                        text="Next.js"
                    />
                    <TechSection
                        icon={PythonSVG}
                        alt="Python logo"
                        text="Python"
                    />
                    <TechSection
                        icon={GraphQLSVG}
                        alt="GraphQL logo"
                        text="GraphQL"
                    />
                    <TechSection
                        icon={NodeSVG}
                        alt="Node logo"
                        text="Node.js"
                    />
                    <TechSection
                        icon={Html5SVG}
                        alt="Html5 logo"
                        text="Html5"
                    />
                    <TechSection
                        icon={Css3SVG}
                        alt="CSS3 logo"
                        text="CSS3"
                    />
                    <TechSection
                        icon={ShopifySVG}
                        alt="Shopify logo"
                        text="Shopify API"
                    />
                    <TechSection
                        icon={AmplifySVG}
                        alt="AWS Amplify logo"
                        text="Amplify"
                    />
                    <TechSection
                        icon={AwsSVG}
                        alt="Amazon Web Services logo"
                        text="AWS"
                    />
                    <TechSection
                        icon={GoogleCloudSVG}
                        alt="Google Cloud Platform logo"
                        text="Google Cloud"
                    />
                    <TechSection
                        icon={PhotoshopSVG}
                        alt="Adobe Photoshop logo"
                        text="Photoshop"
                    />
                    <TechSection
                        icon={IllustratorSVG}
                        alt="Adobe Illustrator logo"
                        text="Illustrator"
                    />
                    <TechSection
                        icon={XdSVG}
                        alt="Adobe Xd logo"
                        text="Adobe Xd"
                    />
                    <TechSection
                        icon={FigmaSVG}
                        alt="Figma logo"
                        text="Figma"
                    />
                </div>            
            </div>
            <div className="bg-gandalfgray mt-4">
                <div className="max-viewport mx-auto pt-10 pb-8 laptop:pb-16">
                    <h2 className="text-center text-lg opacity-70">Some of our latest partners</h2>
                    <div className="w-full flex flex-wrap laptop:mt-8">
                        <div
                            className="w-full flex items-center px-2 laptop:w-1/3 laptop:px-6 laptop:border-r-2"
                            style={{ height: '264px' }}
                        >
                            <img
                                src={IPSSVG}
                                alt="Indian Performance Solutions logo"
                                className="w-full mx-auto"
                                style={{ maxWidth: '420px' }}
                            />
                        </div>
                        <div className="w-full px-4 laptop:hidden">
                            <hr
                                className="bg-black w-full h-2px opacity-20 mx-auto laptop:hidden"
                                style={{ maxWidth: '420px' }}
                            />
                        </div>
                        <div
                            className="w-full flex items-center px-6 laptop:w-1/3 laptop:px-8 laptop:border-r-2"
                            style={{ height: '264px' }}
                        >
                            <img
                                src={Route41SVG}
                                alt="Route 41 Roadhouse restaurant logo"
                                className="w-full mx-auto"
                                style={{ maxWidth: '420px' }}
                            />
                        </div>
                        <div className="w-full px-4 laptop:hidden">
                            <hr
                                className="bg-black w-full h-2px opacity-20 mx-auto"
                                style={{ maxWidth: '420px' }}
                            />
                        </div>
                        <div
                            className="w-full flex items-center px-2 laptop:w-1/3 laptop:px-6"
                            style={{ height: '264px' }}
                        >
                            <img
                                src={RFJSVG}
                                alt="Legal Aid Chicago Relay for Justice logo"
                                className="w-full mx-auto"
                                style={{ maxWidth: '420px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default AboutPage;

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarechicago-socialcard-about"}}) {
        edges {
            node {
            childImageSharp {
                resize(width: 1200) {
                src
                height
                width
                }
            }
            }
        }
        }
    }
`
